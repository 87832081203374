/* eslint no-new-func: 0 */

import type { ISubscriber } from './modules/Subscriber';
import type { ResourceID } from './AppTypes';
import * as crypto from 'crypto-js';
import { iso8601datetime } from './utils/Format';
import type { ActivityTypes } from './types/IActivity';
import type { ITemplateContent } from './modules/Template';

interface ICtx {
    [key: string]: any;
}

interface IMimeDoc {
    headers: {
        [key: string]: string;
    };
    parts: IMimeDoc[];
    body: string;
}

interface ICtxConfiguration {
    id: ResourceID | null;
}

export interface ICtxActivity {
    id: ResourceID | null;
    campaignId: ResourceID | null;
    type: ActivityTypes;
    date: string | null;
    name: string | null;
    target: string | null;
    __template?: SmartTemplate;
}

interface ICtxOrganization {
    id: number | null;
    name: string | null;
    domainsConfig?: {
        sending: Record<string, Record<string, string>>;
        tracking: Record<string, string>;
    };
}

interface ICtxRevision {
    id: ResourceID | null;
    content: string | null;
    configurations: ICtxConfiguration[];
    timestamp: string | null;
    activityId: number | null;
    approvalStatus: string | null;
    integrationId: ResourceID | null;
    integrationConfiguration?: Record<string, any> | null;
}

interface ITemplateRequest {
    activityId: ResourceID | null;
    campaignId: ResourceID | null;
    config: {
        hostname: string;
        mta: string;
        [key: string]: string;
    };
    configurationId: ResourceID | null;
    content: {
        [key: string]: any;
    };
    id: string;
    recipient: Partial<ISubscriber>;
    ref: string;
    revisionId: ResourceID | null;
    test: boolean;
}

const templateBuiltins = `
    var require = function (id, dir, force) {
        var origid = id,
            filename,
            do_force = force || false,
            current_dir = dir || '',
            f, module;
        if (id.substring(0, 2) === './' || id.substring(0, 3) === '../') {
            id = _taguchi.require_normalize(current_dir, id);
        }
        filename = _taguchi.require_dir + id;
        if (_taguchi.packages[filename] === undefined) {
            throw new Error("Can't load module '" + filename + "' from '" +
                current_dir + "': " + JSON.stringify(_taguchi.packages));
        } else if (_taguchi.packages[filename].path) {
            return require(_taguchi.packages[filename].path);
        } else if (!_taguchi.require_cache.hasOwnProperty(filename) || do_force) {
            current_dir = id.substring(0, id.lastIndexOf('/') + 1);
            module = {
                id: id,
                uri: filename,
                parent: {id: null},
                exports: {}
            };
            _taguchi.require_cache[filename] = module.exports;
            _taguchi.require(function(id) { return require(id, current_dir); },
                _taguchi.require_cache[filename], module, filename, current_dir,
                _taguchi.global);
            _taguchi.require_cache[filename] = module.exports;
        }
        return _taguchi.require_cache[filename];
    };
    _taguchi.require_dir = '';
    _taguchi.require_cache = {};
    _taguchi.require_up = function(dir) {
        if (dir === '') {
            throw "Can't go up from ''";
        } else if (dir.charAt(dir.length - 1) !== '/') {
            throw "dir doesn't end in /";
        }
        return dir.substring(0, dir.lastIndexOf('/', dir.length - 2) + 1);
    };
    _taguchi.require_normalize = function(dir, file) {
        for (;;) {
            if (file.substring(0,2) === './') {
                file = file.substring(2);
            } else if (file.substring(0, 3) === '../') {
                file = file.substring(3);
                dir = _taguchi.require_up(dir);
            } else {
                break;
            }
        }
        return dir + file;
    };
`;

function runInContext(code: string) {
    const mfcontext: ICtx = {};

    // Clear out window context
    for (const p in self) {
        mfcontext[p] = undefined;
    }

    // Set up our own context
    mfcontext._taguchi = {
        global: {},
        http_request() {
            alert('HTTPRequest');
        },
        packages: {
            request: { content: '' },
            sys: { content: 'exports.print = exports.debug = exports.log = console.log;' },
            vm: { content: '' },
        },
        require(require: any, exports: any, module: any, jsfilename: string, jsdirname: string, jsglobals: any) {
            const func = new Function(
                'require',
                'exports',
                'module',
                '__filename',
                '__dirname',
                'globals',
                '_taguchi',
                'process',
                'console',
                mfcontext._taguchi.packages[jsfilename].content
            );

            func.call(
                mfcontext,
                require,
                exports,
                module,
                jsfilename,
                jsdirname,
                jsglobals,
                mfcontext._taguchi,
                mfcontext.process,
                mfcontext.console
            );
        },
    };
    mfcontext.process = {};
    mfcontext.console = self.console;

    // Eval builtins and then actual script
    return new Function(`with(this) { ${templateBuiltins}; ${code} }`).call(mfcontext);
}

function parseMIME(s: string) {
    const doc: IMimeDoc = { headers: {}, parts: [], body: '' };
    let headers: string | string[] = '';
    let content = '';
    let splitIdx = s.indexOf('\r\n\r\n');
    let lastHeader: string | null = null;

    // First, find the end of the headers
    headers = s.substring(0, splitIdx + 2);

    // The rest is the body
    content = s.substring(splitIdx + 4);

    // Parse the headers
    headers = headers.split('\r\n');
    for (let i = 0, l = headers.length; i < l; i++) {
        let val = headers[i];

        // Remove empty Q-encoding
        if (val.indexOf('=?utf-8?q??=') !== -1) {
            val = val.replace('=?utf-8?q??=', '');
            // Decode Q-encoding if present in the header line
        } else if (val.indexOf('=?utf-8?q?') !== -1) {
            val = val.replace(/=\?utf-8\?q\?([^?]+)\?=/g, (_, p1) =>
                decodeURIComponent(p1.replace(/_/g, ' ').replace(/=/g, '%'))
            );
        }

        // If this is a continuation line, append it to the previous
        // header value
        if (val.charAt(0) === ' ' || val.charAt(0) === '\t') {
            if (!lastHeader) {
                // Sanity check
                console.error('parseMIME: continuation line but no lastHeader', doc, headers);
                return null;
            }

            doc.headers[lastHeader] += val.replace(/^[\t\r\n\f]+/, '');
        } else {
            // Extract the header name (as lowercase) and value
            splitIdx = val.indexOf(':');
            lastHeader = val.substring(0, splitIdx).toLowerCase().trim();
            doc.headers[lastHeader] = val.substring(splitIdx + 2).trim();
        }
    }

    if (doc.headers['content-type'] && doc.headers['content-type'].indexOf('multipart/alternative') !== -1) {
        // Multipart document; split the parts and parse them recursively
        let boundary = doc.headers['content-type'].split('=')[1];
        boundary = boundary.substring(1, boundary.length - 1);
        const parts = content.split('--' + boundary);

        doc.body = parts[0];

        // Ignore first (body) and last (trailer) parts
        for (let i = 1, l = parts.length - 1; i < l; i++) {
            const mimedoc = parseMIME(parts[i].substring(2));
            if (!mimedoc) {
                continue;
            }
            doc.parts.push(mimedoc);
        }
    } else {
        // Decode UTF-8 output
        doc.body = decodeURIComponent(escape(content));
    }

    return doc;
}

export function findMIMEPart(doc: IMimeDoc, contentType: string): any {
    // Walk a MIME document tree and return the part with the specified
    // content-type
    if (!doc || !doc.headers) {
        return undefined;
    } else if (new RegExp(`^${contentType};`, 'i').test(doc.headers['content-type'])) {
        return doc;
    } else {
        return (doc.parts || []).find((p) => findMIMEPart(p, contentType));
    }
}

export class Context {
    private recipient: Partial<ISubscriber>;
    private activity: ICtxActivity = { id: null, campaignId: null, type: null, date: null, name: null, target: null };
    private revision: ICtxRevision = {
        id: null,
        content: null,
        configurations: [],
        timestamp: null,
        integrationId: null,
        activityId: null,
        approvalStatus: null,
    };
    private organization: ICtxOrganization = { id: null, name: null };

    constructor(
        recipient: Partial<ISubscriber>,
        activity: ICtxActivity | null = null,
        revision: ICtxRevision | null = null,
        organization: ICtxOrganization | null = null
    ) {
        this.recipient = Object.assign(
            {
                address: null,
                address2: null,
                address3: null,
                bounced: null,
                contentBlock: [],
                country: null,
                custom: {},
                dob: null,
                email: 'support@taguchi.com.au',
                emailHash: '',
                firstname: null,
                gender: null,
                id: 0 as ResourceID,
                lastname: null,
                lists: [],
                organizationId: '',
                phone: '',
                postcode: null,
                ref: null,
                segment: {},
                state: null,
                suburb: null,
                title: null,
                unsubscribed: null,
                partition: [],
            },
            recipient
        );

        this.organization = organization || { id: null, name: null };
        this.recipient.emailHash = crypto.SHA256((this.recipient.email || '').toLowerCase()).toString(crypto.enc.Hex);
        this.recipient.organizationId = this.organization.id;

        this.activity = activity || { id: null, campaignId: null, type: null, date: null, name: null, target: null };
        this.revision = revision || {
            id: null,
            content: null,
            configurations: [],
            timestamp: null,
            activityId: null,
            approvalStatus: null,
            integrationId: null,
        };
    }

    public getActivity() {
        return this.activity;
    }

    public getRevision() {
        return this.revision;
    }

    public request(configIdx = 0, requestId = null, liveContent = '') {
        const recipient = JSON.parse(JSON.stringify(this.recipient));
        recipient.hash = '12345678';

        let content: any = JSON.parse(JSON.stringify(liveContent));
        if (!content) {
            content = JSON.parse(JSON.stringify(this.revision.content ?? {}));
        }

        if (content) {
            if (!content.channels) {
                content.channels = Object.create(null);
            }

            // Makes things easier later on
            content.subject = content.subject instanceof Array ? content.subject : [content.subject || ''];

            // Handle selection of content alternatives while optimising
            const isMultivariate = content.multivariate === 'yes';
            let numConfigs = 1;

            // Work out the number of configurations. If it's a multivariate
            // test, that's the product of the number of alternatives in each
            // block. If it's not a multivariate test, use the smallest number
            // of alternatives instead.
            const altItemCounts = Object.keys(content.channels)
                .map((key) =>
                    (content.channels[key].items || [])
                        .map((i: any) => {
                            if (Object.hasOwn(i, 'items')) {
                                // For v5 templates: extract the alternative blocks from the grid rows (layouts)
                                return i.items.filter((j: any) => j instanceof Array).flat();
                            } else {
                                // Pre-v5 (v4 UI, v1/v2 templates)
                                return i instanceof Array ? i : [];
                            }
                        })
                        .filter((i: any) => i instanceof Array && i.length > 0)
                        .map((i: any) => i.length)
                )
                .concat(
                    Object.keys(content)
                        .filter((key) => key !== 'channels' && content[key] instanceof Array)
                        .map((i: any) => content[i].length)
                )
                .reduce((a, b) => a.concat(b), []);

            if (isMultivariate) {
                numConfigs = altItemCounts.reduce((m: number, k: number) => m * k, numConfigs);
            } else {
                numConfigs = Math.min(numConfigs, Math.min.apply(null, altItemCounts));
            }

            configIdx = Math.max(0, Math.min(numConfigs, configIdx));

            if (isMultivariate) {
                // Apply content selection for each group -- essentially a
                // mixed-radix base conversion with the subject being the
                // least significant digit.
                let idx = configIdx;

                Object.keys(content).forEach((key) => {
                    if (key !== 'channels' && content[key] instanceof Array) {
                        const selectedAlternative = content[key][idx % content[key].length];
                        idx = Math.floor(idx / content[key].length);
                        content[key] = selectedAlternative;
                    }
                });

                Object.keys(content.channels).forEach((key) => {
                    content.channels[key].items = (content.channels[key].items || []).map((item: any) => {
                        if (Object.hasOwn(item, 'items')) {
                            // For v5 templates: extract the alternative blocks from within the grid row (layout)
                            item.items = item.items.map((i) => {
                                if (i instanceof Array) {
                                    const selectedAlternative = i[idx % i.length];
                                    idx = Math.floor(idx / i.length);
                                    return selectedAlternative;
                                }
                                return i;
                            });
                            return item;
                        } else {
                            if (item instanceof Array) {
                                const selectedAlternative = item[idx % item.length];
                                idx = Math.floor(idx / item.length);
                                return selectedAlternative;
                            } else {
                                return item;
                            }
                        }
                    });
                });
            } else {
                // Simply select the alternative with index equal to configIdx for all
                // blocks with alternatives.
                Object.keys(content).forEach((key) => {
                    if (key !== 'channels' && content[key] instanceof Array) {
                        content[key] = content[key][configIdx] || '';
                    }
                });
                Object.keys(content.channels).forEach((key) => {
                    content.channels[key].items = (content.channels[key].items || []).map((item: any) => {
                        if (Object.hasOwn(item, 'items')) {
                            // For v5 templates: extract the alternative blocks from within the grid row (layout)
                            item.items = item.items.map((i) => {
                                if (i instanceof Array) {
                                    return i[configIdx];
                                }
                                return i;
                            });
                            return item;
                        } else {
                            return item instanceof Array ? item[configIdx] : item;
                        }
                    });
                });
            }
        }

        let trackingServer = self.location.hostname;
        let mailFrom = 'clients.taguchimail.com';
        const defaultMailFrom = 'clients.taguchimail.com';
        if (content?.sender?.from && ['email', 'web'].includes(this.activity.type ?? 'email')) {
            trackingServer = content?.theme?.content?.trackingDomain ?? trackingServer;
            if (
                content?.sender?.from?.domainName &&
                Object.keys(this.organization.domainsConfig?.sending ?? {}).length > 0
            ) {
                const domainDetail = this.organization.domainsConfig?.sending[content.sender.from.domainName];
                if (domainDetail) {
                    const isDefault =
                        domainDetail.envfrom?.trim() === 'default' || domainDetail.envfrom?.trim() === 'taguchi';

                    if (isDefault) {
                        mailFrom = defaultMailFrom;
                    } else {
                        mailFrom = domainDetail.envfrom ?? defaultMailFrom;
                    }
                }
            }
        } else if (
            ['sms', 'whatsapp'].includes(this.activity.type ?? 'sms') &&
            this.revision.integrationConfiguration?.trackingDomain &&
            this.revision.integrationConfiguration?.trackingDomain !== ''
        ) {
            trackingServer = this.revision.integrationConfiguration?.trackingDomain;
        }

        return {
            activityId: this.activity.id as ResourceID,
            campaignId: this.activity.campaignId as ResourceID,
            config: {
                hostname: trackingServer,
                mta: mailFrom,
            },
            configurationId:
                this.revision.configurations && configIdx < this.revision.configurations.length
                    ? this.revision.configurations[configIdx].id
                    : 1,
            content: JSON.parse(JSON.stringify(content)),
            contentBlocks: {},
            id: requestId || 'preview',
            recipient: this.recipient,
            ref: ['email', 'sms', 'line', 'pushnotification', 'whatsapp'].includes(this.activity.type as string)
                ? 'send'
                : 'view',
            revisionId: this.revision.id,
            revisionTimestamp:
                (this.revision.timestamp ? this.revision.timestamp : iso8601datetime(new Date())).replace(
                    /[:\-Z]/g,
                    ''
                ) + 'Z',
            scheduleTimestamp:
                (this.activity.date ? this.activity.date : iso8601datetime(new Date())).replace(/[:\-Z]/g, '') + 'Z',
            test: true,
            activityName: this.activity.name,
            activityTargetExpression: this.activity.target,
            organizationName: this.organization.name,
            outputChannel: ['email', 'sms', 'pushnotification', 'whatsapp'].includes(this.activity.type ?? 'email')
                ? this.activity.type
                : 'web',
            partitions: {},
        };
    }
}

export class SmartTemplate {
    private template: ITemplateContent;
    constructor(templateData: ITemplateContent) {
        this.template = templateData;
    }

    public get templateData() {
        return this.template;
    }

    public get requestSample() {
        return this.template?.files['test/sample.json'] ?? {};
    }

    public get version() {
        const pkg = JSON.parse(this.template?.files['package.json'] ?? {});
        return pkg.version ?? 0;
    }

    public get majorVersion() {
        return parseInt(this.version.split('.')[0] ?? '0', 10);
    }
    public get codeHash() {
        return this.template.hash;
    }

    public getProfiles() {
        const profiles = this.template?.files['test/profiles.json'] ?? {};
        if (!profiles) {
            return {};
        }

        try {
            return JSON.parse(profiles);
        } catch (ignore) {
            return {};
        }
    }

    public hasScriptTag() {
        const parser = new DOMParser();
        const ddoc = parser.parseFromString(this.template.files['datadescription.xml'], 'application/xml');
        const templateNode = ddoc.children[0];
        if (templateNode.tagName !== 'template') {
            return false;
        }
        return [].some.call(templateNode.childNodes, (c) => c.tagName === 'script');
    }

    public render(request: ITemplateRequest) {
        if (!this.template) {
            return { error: 'No template selected' };
        }

        if (!this.template.productionCode) {
            return { error: 'Template is missing production code' };
        }

        request.content = Object.freeze(request.content || JSON.parse(this.template.files['test/sample.json']));
        const output = runInContext(`
            ${this.template.productionCode};
            template.doLoad();
            return template.handleRequest(${JSON.stringify(request)
                .replace(/\u2028/g, '\\u2028')
                .replace(/\u2029/g, '\\u2029')});
        `)[0];

        if (output.error) {
            return output;
        }

        try {
            output.parsed = parseMIME(output.content);
        } catch (exc) {
            output.parsed = null;
            output.exception = exc;
        }
        return output;
    }
}
